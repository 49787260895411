import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';

import { CaseCount } from '../components/CaseCount';
import { DefaultContent } from '../components/DefaultContent';
import { Hero, HeroBackground } from '../components/Hero';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { SignupForm } from '../forms/SignupForm';
import { Title } from '../styles/common';

const SignUp: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Sign Up" description="Create your Hypercite account and start hyperlinking" />
      <Hero className="flex flex-col items-center w-full">
        <Title className="text-center mt-16 mb-12">
          Automatically Hyperlink Your Citations to the Record, Prior Briefing, and More In Just Seconds
        </Title>
        <HeroBackground />
        <div className="w-full flex px-4">
          <div className="w-full md:w-2/3 lg:w-1/2 ml-auto mr-auto">
            <SignupForm />
          </div>
        </div>

        <CaseCount />
      </Hero>

      <DefaultContent />
    </PublicRoute>
  );
};

export default SignUp;
